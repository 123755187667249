<template>
  <div>
    <div class="mb-4">
      <img
        :src="ErboLogo"
        alt="aw"
      >
    </div>
    <h3 class="text-center font-weight-bolder mb-2">
      {{ $t(CREATE_PAGE_TITLE_PREFIX) }}
      —
      {{ getValueFromGivenObjectByKey(assetItem, 'product.sku', '') }}
      {{ getValueFromGivenObjectByKey(assetItem, 'product.name', '') }}
    </h3>
    <quote-status-bar
      :item="order"
      :quote-status-bar="quoteStatusBar"
      class="mb-4"
    />
    <order-information class="mb-1" />
    <asset class="mb-1" />
    <div class="mb-1">
      <dynamic-table
        :title="SERVICES_PERFORMED_TABLE_TITLE"
        list-name="servicesPerformed"
        class="mb-1"
      />
      <dynamic-table
        :title="OTHER_CHARGES_ITEMS_TABLE_TITLE"
        list-name="otherCharges"
      />
    </div>
    <notes-and-totals v-if="!isOrderInInvoice" />
  </div>
</template>

<script>

import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import ErboLogo from '@/assets/images/ico/erbo-logo.svg'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import OrderInformation
from '@/views/main/orders/view/service/components/create/received-quote/components/OrderInformation.vue'
import Asset from '@/views/main/orders/view/service/components/common/Asset.vue'
import DynamicTable from '@/views/main/orders/view/service/components/common/DynamicTable/DynamicTable.vue'
import generalConfig from '@/views/main/orders/view/service/config'
import config from '@/views/main/orders/view/service/components/create/received-quote/config'
import NotesAndTotals
  from "@/views/main/orders/view/service/components/create/received-quote/components/NotesAndTotals.vue";

export default {
  name: 'ServiceOrderPrintTemplate',
  components: {
    NotesAndTotals,
    DynamicTable,
    Asset,
    OrderInformation,
    QuoteStatusBar,

  },
  data() {
    return {
      quoteStatusBarItems: this.quoteStatusBar,
    }
  },

  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    serviceType() {
      return this.$store.state[this.MODULE_NAME].serviceType.value
    },
    serviceOrderInformationFormDefaultValue() {
      return this.$store.state[this.MODULE_NAME].serviceOrderInformationFormDefaultValue
    },
    assetItem() {
      return this.$store.state[this.MODULE_NAME].assetItem
    },
    isOrderInInvoice() {
      return this.order.status === 1 && this.order.state === 2
    },
  },
  watch: {
    serviceType(newVal) {
      this.changeServiceTypeByType(newVal)
    },
  },
  methods: {
    changeServiceTypeByType(type) {
      if (this.SERVICE_TYPE_SEND_OUT_SERVICE === type) {
        this.quoteStatusBarItems[1].title = 'Service Order'
        this.quoteStatusBarItems[2].title = 'Invoice'
      }
      if (this.SERVICE_TYPE_IN_HOUSE_SERVICE === type) {
        this.quoteStatusBarItems[1].title = 'In-House Service'
        this.quoteStatusBarItems[2].title = 'Bill'
      }
    },
    setServiceType(type) {
      this.changeServiceTypeByType(type)
    },
  },
  setup() {
    const {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE_PREFIX,
      CREATE_PAGE_TITLE_SUFFIX,
      SERVICE_TYPE_SEND_OUT_SERVICE,
      SERVICE_TYPE_IN_HOUSE_SERVICE,
    } = generalConfig()

    const {
      SERVICES_PERFORMED_TABLE_TITLE,
      OTHER_CHARGES_ITEMS_TABLE_TITLE,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE_PREFIX,
      CREATE_PAGE_TITLE_SUFFIX,
      getValueFromGivenObjectByKey,
      SERVICE_TYPE_SEND_OUT_SERVICE,
      SERVICE_TYPE_IN_HOUSE_SERVICE,
      SERVICES_PERFORMED_TABLE_TITLE,
      ErboLogo,
      OTHER_CHARGES_ITEMS_TABLE_TITLE,
    }
  },
}

</script>

<style scoped lang="scss">

</style>
