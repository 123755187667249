<template>
  <div>
    <div>
      <error-alert
        :fields="{}"
        :error="{}"
      />
      <!--   Form Body   -->
      <b-form>
        <validation-observer
          ref="requestServiceQuoteForm"
        >
          <order-information class="mb-1" />
          <asset class="mb-1" />
          <div class="mb-1">
            <dynamic-table
              :title="SERVICES_PERFORMED_TABLE_TITLE"
              list-name="servicesPerformed"
              class="mb-1"
            />
            <dynamic-table
              :title="OTHER_CHARGES_ITEMS_TABLE_TITLE"
              list-name="otherCharges"
            />
          </div>
          <div
            class="bg-white p-1 mb-1"
            style="border-radius: 5px"
          >
            <upload-attachment
              :title="RECEIVED_ATTACHMENTS"
              class="service-attachment"
              :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']"
            />
          </div>
          <notes-and-totals v-if="!isOrderInInvoice" />
        </validation-observer>
      </b-form>
    </div>
    <form-bottom :form-refs="$refs" />
    <!--    <b-s-warning-modal-->
    <!--      ref="confirm-modal"-->
    <!--      @onNextTick="onNextTick"-->
    <!--    />-->
  </div>
</template>

<script>
// Components
import { BForm } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import DynamicTable from '@/views/main/orders/view/service/components/common/DynamicTable/DynamicTable.vue'
import config from './config'
import generalConfig from '../../../config'
import FormBottom from './FormBottom.vue'
import OrderInformation from './components/OrderInformation.vue'
import NotesAndTotals from './components/NotesAndTotals.vue'
import Asset from '../../common/Asset.vue'
import UploadAttachment from '../../common/UploadAttachment.vue'

export default {
  name: 'Form',
  components: {
    Asset,
    BForm,
    ErrorAlert,
    FormBottom,
    DynamicTable,
    NotesAndTotals,
    UploadAttachment,
    OrderInformation,
    ValidationObserver,
  },
  data() {
    return {
      onSubmit: false,
    }
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order ?? {}
    },
    serviceTypeValue() {
      return this.$store.state[this.MODULE_NAME].serviceType
    },
    isOrderTypeSendOutForServices() {
      return this.SERVICE_TYPE_SEND_OUT_SERVICE_RESPONSE_ITEM_TYPE === this.order.service_type
    },
    isOrderInInvoice() {
      return this.order.status === 1 && this.order.state === 2
    },
  },
  methods: {
    onServiceTypeChange(type) {
      this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_TYPE_ON_CHANGE`, type)
    },
  },
  setup() {
    const {
      serviceType,
      MODULE_NAME_CLONE,
      storeMutationsList,
      RECEIVED_ATTACHMENTS,
      orderInformationFields,
      ORDER_INFORMATION_FORM_TITLE,
      SERVICE_TYPE_SEND_OUT_SERVICE,
      SERVICE_TYPE_IN_HOUSE_SERVICE,
      SERVICES_PERFORMED_TABLE_TITLE,
      OTHER_CHARGES_ITEMS_TABLE_TITLE,
      SERVICE_ORDER_INFORMATION_TITLE,
      SERVICE_TYPE_SEND_OUT_SERVICE_RESPONSE_ITEM_TYPE,
    } = config()

    const { quoteStatusBar, MODULE_NAME } = generalConfig()

    return {
      serviceType,
      MODULE_NAME,
      quoteStatusBar,
      MODULE_NAME_CLONE,
      storeMutationsList,
      RECEIVED_ATTACHMENTS,
      orderInformationFields,
      ORDER_INFORMATION_FORM_TITLE,
      SERVICE_TYPE_SEND_OUT_SERVICE,
      SERVICE_TYPE_IN_HOUSE_SERVICE,
      SERVICES_PERFORMED_TABLE_TITLE,
      OTHER_CHARGES_ITEMS_TABLE_TITLE,
      SERVICE_ORDER_INFORMATION_TITLE,
      SERVICE_TYPE_SEND_OUT_SERVICE_RESPONSE_ITEM_TYPE,
    }
  },
}
</script>
<style lang="scss">
  .service-attachment .attachments-row {
    flex-direction: row !important;
  }
</style>
