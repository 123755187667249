<template>
  <div>

    <b-row>
      <b-col cols="6">
        <div class="form-wrapper">
          <h3 class="font-weight-bolder text-body">
            {{ $t(NOTES_TITLE) }}
          </h3>
          <component
            :is="notesFields[field].type"
            v-for="field in ['internal_order_reference', 'internal_notes']"
            :key="field"
            v-model="notes[field]"
            v-bind="getProps(field, notesFields)"
            :is-editable="true"
          />
          <component
            :is="notesFields['received_comments'].type"
            v-model="notes['received_comments']"
            v-bind="getProps('received_comments', notesFields)"
          />
        </div>
      </b-col>
      <b-col cols="6">

        <div class="form-wrapper">
          <h3 class="font-weight-bolder text-body">
            {{ $t(TOTALS_TITLE) }}
          </h3>
          <b-list-group class="totals-form-list-group">
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ SERVICES_PERFORMED_TABLE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(calcTotalOfDynamicTable('servicesPerformed')) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ OTHER_CHARGES_ITEMS_TABLE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(calcTotalOfDynamicTable('otherCharges')) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_DISCOUNTS_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  -{{ formatNumberToCurrencyView(discountsSummation) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TAXES_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(taxesSummation) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TOTAL_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(totals) }}
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
    </b-row>

  </div>

</template>

<script>
import {
  BCol, BRow, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import minConfig from '@/views/main/orders/view/service/config'
import { getProps, sumTotalAmountOfGivenArrayByProperty, formatNumberToCurrencyView } from '@core/utils/utils'
// eslint-disable-next-line import/no-named-as-default
import config from '../config'

export default {
  name: 'NotesAndTotals',
  components: {
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    taxRule() {
      return this.order.customer?.customer_detail?.tax_rule?.value ?? 0
    },
    notes() {
      return this.$store.state[this.MODULE_NAME].notes
    },
    servicesPerformed() {
      return this.$store.state[this.MODULE_NAME].servicesPerformed
    },
    otherCharges() {
      return this.$store.state[this.MODULE_NAME].otherCharges
    },
    discountsSummation() {
      return this.sumTotalAmountOfGivenArrayByProperty(this.servicesPerformed, 'discount') + this.sumTotalAmountOfGivenArrayByProperty(this.otherCharges, 'discount')
    },
    taxesSummation() {
      const servicesPerformedSummation = this.calculateTotalTax('servicesPerformed')
      const otherChargesSummation = this.calculateTotalTax('otherCharges')
      return +servicesPerformedSummation + +otherChargesSummation
    },
    totals() {
      const servicesPerformedSummation = this.calcTotalOfDynamicTable('servicesPerformed')
      const otherChargesSummation = this.calcTotalOfDynamicTable('otherCharges')
      return +servicesPerformedSummation + +otherChargesSummation + +this.taxesSummation - +this.discountsSummation
    },
  },
  methods: {
    calcTotalOfDynamicTable(actionType) {
      return this.sumTotalAmountOfGivenArrayByProperty(this?.[actionType]?.map(charge => ({
        total:
            (charge.price
                * charge.unit
            ),
      })), 'total')
    },
    calculateTotalTax(actionType) {
      return this.sumTotalAmountOfGivenArrayByProperty(this?.[actionType]?.map(charge => ({
        taxSum: ((charge.price * charge.unit) - charge.discount) * (+this.taxRule / 100),
      })), 'taxSum')
    },
  },
  setup() {
    const {
      NOTES_TITLE,
      notesFields,
      TOTALS_TITLE,
      TOTALS_TAXES_TITLE,
      TOTALS_TOTAL_TITLE,
      RECEIVED_ATTACHMENTS,
      TOTALS_DISCOUNTS_TITLE,
      SERVICES_PERFORMED_TABLE_TITLE,
      OTHER_CHARGES_ITEMS_TABLE_TITLE,
    } = config()
    const { MODULE_NAME } = minConfig()

    return {
      NOTES_TITLE,
      getProps,
      notesFields,
      MODULE_NAME,
      TOTALS_TOTAL_TITLE,
      TOTALS_TAXES_TITLE,
      TOTALS_DISCOUNTS_TITLE,
      formatNumberToCurrencyView,
      sumTotalAmountOfGivenArrayByProperty,
      TOTALS_TITLE,
      RECEIVED_ATTACHMENTS,
      SERVICES_PERFORMED_TABLE_TITLE,
      OTHER_CHARGES_ITEMS_TABLE_TITLE,
    }
  },
}
</script>
<style lang="scss">
.totals-form-list-group {

  &-item {
  &:nth-child(even) {
    background-color: #FBFBFB;
  }
  padding: 5px 1.25rem !important;

  &:hover {
    background-color: transparent !important;
  }

  &-light {
    background-color: #FBFBFB !important;

    &:hover {
      background-color: #FBFBFB !important;
    }
  }

  }

}
</style>
