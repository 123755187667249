<template>
  <div>
    <div class="form-wrapper mb-1">
      <h3
        class="font-weight-bolder mb-2"
        style="color: #646464"
      >
        {{ $t(SERVICE_ORDER_INFORMATION_TITLE) }}
      </h3>

      <b-row>
        <b-col
          v-for="({ label }, idx) in orderInformationObjectForRender"
          :key="idx"
          cols="6"
        >
          <b-row style="margin-bottom: 8px">
            <b-col cols="6">
              <b style="color: #646464">{{ label }}</b>
            </b-col>
            <b-col cols="6">
              <b-skeleton
                v-if="label"
                class="mb-0"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    </div>
    <div class="form-wrapper mb-1">
      <h3
        class="font-weight-bolder mb-2"
        style="color: #646464"
      >
        {{ $t(ASSET_TABLE_TITLE) }}
      </h3>
      <asset-skeleton />
    </div>
    <div class="form-wrapper mb-1">
      <h3
        class="font-weight-bolder mb-2"
        style="color: #646464"
      >
        {{ $t(SERVICES_PERFORMED_TABLE_TITLE) }}
      </h3>
      <asset-skeleton />
    </div>
    <div class="form-wrapper mb-1">
      <h3
        class="font-weight-bolder mb-2"
        style="color: #646464"
      >
        {{ $t(OTHER_CHARGES_ITEMS_TABLE_TITLE) }}
      </h3>
      <asset-skeleton />
    </div>
    <div class="form-wrapper mb-1">
      <h3
        class="font-weight-bolder mb-2"
        style="color: #646464"
      >
        {{ $t(RECEIVED_ATTACHMENTS) }}
      </h3>
      <b-row>
        <b-col>
          <asset-skeleton style="margin-bottom: 5px" />
          <asset-skeleton />
        </b-col>
        <b-col>
          <div
            class="border-dashed d-flex justify-content-center align-items-center"
            style="height: 116px"
          >
            {{ $t('Drop Files Here to Upload') }}
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <div class="form-wrapper mb-1">
          <h3
            class="font-weight-bolder mb-2"
            style="color: #646464"
          >
            {{ $t(NOTES_TITLE) }}
          </h3>
          <div
            v-for="({ label }, indx) in notesFields"
            :key="indx"
          >
            <b class="pl-1">{{ $t(label) }}</b>
            <b-skeleton
              width="100%"
              height="38px"
            />
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="form-wrapper mb-1">
          <h3
            class="font-weight-bolder mb-2"
            style="color: #646464"
          >
            {{ $t(TOTALS_TITLE) }}
          </h3>
          <b-list-group class="totals-form-list-group">
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ SERVICES_PERFORMED_TABLE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <b-skeleton
                    width="100%"
                    height="22px"
                    class="mb-0"
                  />
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ OTHER_CHARGES_ITEMS_TABLE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <b-skeleton
                    width="100%"
                    height="22px"
                    class="mb-0"
                  />
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_DISCOUNTS_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <b-skeleton
                    width="100%"
                    height="22px"
                    class="mb-0"
                  />
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TAXES_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <b-skeleton
                    width="100%"
                    height="22px"
                    class="mb-0"
                  />
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TOTAL_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <b-skeleton
                    width="100%"
                    height="22px"
                    class="mb-0"
                  />
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>

        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import AssetSkeleton from '@/views/main/orders/view/service/components/common/AssetSkeleton.vue'
import {
  BSkeleton, BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import mainConfig from '@/views/main/orders/view/service/config'
import config from '../config'

export default {
  name: 'Skeleton',
  components: {
    BListGroupItem,
    BListGroup,
    BRow,
    BCol,
    BSkeleton,
    AssetSkeleton,
    QuoteStatusBar,
  },
  setup() {
    const {
      notesFields,
      NOTES_TITLE,
      TOTALS_TITLE,
      TOTALS_TOTAL_TITLE,
      TOTALS_TAXES_TITLE,
      RECEIVED_ATTACHMENTS,
      TOTALS_DISCOUNTS_TITLE,
      SERVICES_PERFORMED_TABLE_TITLE,
      OTHER_CHARGES_ITEMS_TABLE_TITLE,
      orderInformationObjectForRender,
      SERVICE_ORDER_INFORMATION_TITLE,
    } = config()

    const { quoteStatusBar, ASSET_TABLE_TITLE } = mainConfig()

    return {
      notesFields,
      NOTES_TITLE,
      TOTALS_TITLE,
      quoteStatusBar,
      ASSET_TABLE_TITLE,
      TOTALS_TOTAL_TITLE,
      TOTALS_TAXES_TITLE,
      RECEIVED_ATTACHMENTS,
      TOTALS_DISCOUNTS_TITLE,
      SERVICES_PERFORMED_TABLE_TITLE,
      OTHER_CHARGES_ITEMS_TABLE_TITLE,
      orderInformationObjectForRender,
      SERVICE_ORDER_INFORMATION_TITLE,
    }
  },
}
</script>
