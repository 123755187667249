var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-form-bottom',{ref:"form-bottom",attrs:{"path":"home-orders-service-list"}},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"8px"}},_vm._l((_vm.isOrderInInvoice ? _vm.formBottomActionsInvoice : _vm.formBottomActions),function(ref,idx){
var variant = ref.variant;
var style = ref.style;
var action = ref.action;
var title = ref.title;
var icon = ref.icon;
var formSubmitAction = ref.formSubmitAction;
return _c('b-button',{key:idx,staticClass:"p-0 d-flex justify-content-center align-items-center",style:(style),attrs:{"variant":variant,"disabled":_vm.disableByStateAndStatus(action)},on:{"click":function($event){return _vm.buttonEvent(formSubmitAction, action)}}},[_c('Printer',[_c('div',{attrs:{"id":"print","hidden":""}},[_c('ServiceOrderPrintTemplate')],1)]),(_vm.loadingFor.isLoading && _vm.loadingFor.action === action)?_c('b-spinner',{attrs:{"small":"","variant":"secondary"}}):_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"8px"}},[(icon)?_c('feather-icon',{attrs:{"icon":icon}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$t(title))+" ")])],1)],1)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }